(function () {
  'use strict';

  class DashboardCtrl {
    constructor($rootScope, User, $state, env, Media, Resources, Modules, Notifications, $mdMedia, $mdSidenav, $mdToast, $scope, Utils, Groups, ActionCableSocketWrangler, ActionCableChannel, $window) {
      let vm = this;
      vm.ctrlName = 'DashboardCtrl';
      vm.$rootScope = $rootScope;
      vm.$window = $window;
      vm.user = vm.$rootScope.user;
      vm.User = User;
      vm.Notifications = Notifications;
      vm.$state = $state;
      vm.env = env;
      vm.Media = Media;
      vm.Groups = Groups;
      vm.Resources = Resources;
      vm.Modules = Modules;
      vm.$mdMedia = $mdMedia;
      vm.selectedResource = [];
      vm.$mdSidenav = $mdSidenav;
      vm.$mdToast = $mdToast;
      vm.Utils = Utils;
      vm.$scope = $scope;
      vm.ActionCableSocketWrangler = ActionCableSocketWrangler;
      vm.ActionCableChannel = ActionCableChannel;
      vm.resource = {
        keywords: []
      };
      if (vm.user.groups && vm.user.groups[0]) {
        vm.Groups.groupIndex({id: vm.user.groups[0].id})
          .then((data)=>{
            vm.group = data;
          });
      }
      $scope.$on('$destroy', ()=> {
        if (vm.ProfileChannel && vm.ProfileChannel.unsubscribe) {
          vm.ProfileChannel.unsubscribe();
        }
        if (ActionCableSocketWrangler.connected) {
          ActionCableSocketWrangler.stop();
        }
      });
      // vm.socketStart();
      // vm.init();
    }
    socketStart() {
      let vm = this;
      if (!vm.ActionCableSocketWrangler.connected && !vm.ActionCableSocketWrangler.connecting) {
        vm.ActionCableSocketWrangler.start();
      }
      vm.ProfileChannel = new vm.ActionCableChannel('ProfileChannel', {user_id: vm.user.id});
      vm.ProfileChannel.subscribe(function (data) {
        if (data.notification && vm.notifications) {
          data.notification.new = true;
          vm.newItem = true;
          vm.notifications.unshift(data.notification);
        }
        vm.$rootScope.$emit('notificationIndex', data);
      });
    }
    init() {
      let vm = this;
      vm.Notifications.notificationsIndex()
        .then((data)=> {
          vm.notifications = data.documents;
        });
    }
    toggleSideNav() {
      const vm = this;
      vm.$mdSidenav('left').toggle();
    }
    openMenu($mdMenu, ev) {
      let vm = this;
      vm.newItem = false;
      $mdMenu.open(ev);
    }
    logout() {
      const vm = this;
      if (vm.$rootScope.selectedGroup) {
        delete vm.$rootScope.selectedGroup;
      }
      if (vm.$rootScope.classroomId) {
        delete vm.$rootScope.classroomId;
      }
      vm.User.logout();
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent('you have been logged out')
          .position('bottom right')
          .hideDelay(3000)
      );
      vm.$state.go('home');
    }
    updateUser() {
      const vm = this;
      vm.User.update(vm.user)
        .then((data)=> {
          const {user} = data;
          vm.user = user;
          vm.$mdToast.show(
            vm.$mdToast.simple()
              .textContent('Profile updated')
              .position('bottom right')
              .hideDelay(3000)
          );
        });
    }
    uploadMedia() {
      const vm = this;
      vm.Media.mediaCreate(vm.media.file)
        .then((data)=>{
          vm.user.avatar_id = data.media.id;
          vm.updateUser();
        });
    }
    routeChange(url, params, blank) {
      params = params || {};
      const vm = this,
          uri = vm.$state.href(url, params);
      if (blank) {
        vm.$window.open(uri, '_blank');
      } else {
        vm.$state.go(url, params);
      }
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.controller:DashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard')
    .controller('DashboardCtrl', DashboardCtrl);
}());
